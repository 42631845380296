



















// eslint-disable-next-line object-curly-newline
import { mapGetters, mapActions, mapState } from 'vuex';
import RepositoryFactory from '@/services/RepositoryFactory';
import { IOrdersRepository, IOrder } from '@/services/Repositories/OrdersRepository/types';
import IBoxShippingsRepository from '@/services/Repositories/BoxShippings/types';
import BoxCustomerInformation from '@/components/boxes/OrderCustomerInformation.vue';
import TheFabButton from '@/components/ui/TheFabButton.vue';
import OrderLabelsList from '@/components/boxes/OrderLabelsList.vue';
import PrintMixin from '@/mixins/PrintMixin';

const Factory = new RepositoryFactory();

type ReboxPrint = {
  code: string;
  label: string;
};

export default PrintMixin.extend({
  name: 'OrderDetails',
  data() {
    return {
      labels: [] as ReboxPrint[],
    };
  },
  components: {
    BoxCustomerInformation,
    TheFabButton,
    OrderLabelsList,
  },
  computed: {
    ...mapState('globals', ['loader']),
    ...mapGetters('orders', ['getOrder']),
    orderId(): string {
      const {
        params: { orderId },
      } = this.$route;
      return orderId;
    },
    orderBoxes(): boolean {
      return !!this.order.boxShippings.length;
    },
    order(): IOrder {
      return this.getOrder(this.orderId);
    },
    ordersRepository(): IOrdersRepository {
      return Factory.get('orders') as IOrdersRepository;
    },
    shippingRepository(): IBoxShippingsRepository {
      return Factory.get('boxShippings') as IBoxShippingsRepository;
    },
    fabLabel(): string {
      return this.orderBoxes ? 'fabButton.printLabels' : 'inputs.set-up-rebox';
    },
    fabIcon(): string {
      return this.orderBoxes ? 'printer' : 'boxicon';
    },
  },
  methods: {
    ...mapActions('orders', ['addOrder']),
    createRebox() {
      this.$router.replace({ name: 'findRebox', params: { orderId: this.orderId } });
    },
    handleFabEvent(): void {
      if (this.orderBoxes) {
        const labels = this.labels.map(({ label }) => label);
        this.printAllLabels(labels);
      } else {
        this.createRebox();
      }
    },
    async fetchOrder() {
      const { data } = await this.ordersRepository.getById(this.orderId);
      this.addOrder(data);
    },
    async getLabes(): Promise<void> {
      if (this.orderBoxes) {
        const { boxShippings } = this.order;
        const labels: ReboxPrint[] = await Promise.all(
          boxShippings.map(async (item) => {
            const id = item.split('/').pop() || '';
            const { data } = await this.shippingRepository.getOne(id);
            const {
              boxShipmentLabel: { file },
              box: { code },
            } = data;
            return { code, label: file };
          }),
        );
        this.labels = labels;
      }
    },
  },
  async created() {
    const { icons, actions } = this.$headerService.getIconsAndActions();
    this.$headerService.config({
      icons: { icon: icons.back, action: actions.goBack },
      page: { name: 'allOrders' },
    });

    if (this.order) {
      this.$headerService.setHeaderTitle(`order ${this.order.orderNr}`);
      await this.getLabes();
    }
    await this.fetchOrder();
    this.$headerService.setHeaderTitle(`order ${this.order.orderNr}`);
  },
});
