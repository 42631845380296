












import Vue from 'vue';
import { IOrderContext } from '@/services/Repositories/OrdersRepository/types';

export default Vue.extend({
  name: 'BoxCustomerInformation',
  props: {
    order: {
      type: Object as () => IOrderContext,
      required: true,
    },
  },
  computed: {
    orderDetails(): any {
      const {
        '@id': _id,
        '@type': _type,
        '@context': _context,
        id,
        orderNr,
        merchant,
        boxShippings,
        updatedAt,
        updatedBy,
        createdAt,
        createdBy,
        custCompany,
        custEmail,
        custName,
        custNr,
        custSurname,
        delAddressCity,
        delAddressStreet,
        delAddressStreetNr,
        delAddressZip,
        shipmentMethod,
        shipmentProvider,
        deliveryNoteNr,
        deliveryDate,
        shipStatus,
        ...rest
      } = this.order;
      const filteredOrder = {
        orderNr,
        custNr,
        customer: `${custName} ${custSurname}`,
        address: `${delAddressStreet} ${delAddressStreetNr}, ${delAddressZip} ${delAddressCity}`,
        custCompany,
        custEmail,
        shipmentMethod,
        shipmentProvider,
        deliveryNoteNr,
        deliveryDate,
        shipStatus,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
      };
      return filteredOrder;
    },
  },
});
