





















import { PrinterIcon } from 'vue-feather-icons';
import PrintMixin from '@/mixins/PrintMixin';

type ReboxPrint = {
  code: string;
  label: string;
};

export default PrintMixin.extend({
  name: 'OrderLabelsList',
  props: {
    labels: {
      type: Array as () => ReboxPrint[],
      required: true,
      default: () => [],
    },
  },
  components: {
    PrinterIcon,
  },
});
